<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="mapeamentoDeRetina"
  >
    <div class="form-group">
      <b-col cols="12">
        <toggle-button
          class="autoFillBtn"
          v-model="autoCompleted"
          :sync="true"
          :height="24"
          :disabled="!canEdit"
          :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
          @change="autoFill"
        />
        <span class="autoFillBtnLabel">Marcar campos em branco como “sem alterações”</span>
      </b-col>
      <b-col cols="12">
        <toggle-button
          class="autoFillBtn"
          v-model="padrao"
          :sync="true"
          :height="24"
          :disabled="!canEdit || !useStandard"
          :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
          @change="setPadrao"
        />
        <span class="autoFillBtnLabel">Preencher com registro padrão</span>
      </b-col>
      <b-col cols="12">
        <toggle-button
          class="autoFillBtn"
          :value="simplified"
          :sync="true"
          :height="24"
          @change="handleUseSimplified"
          :disabled="!canEdit"
          :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
        />
        <span class="autoFillBtnLabel">Visualização padrão simplificada</span>
      </b-col>
    </div>
    <b-col cols="12" v-if="simplified" >
      <b-row>
        <b-col>
          <div class="form-group ">
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <span for="simplified-right" class="tag-eye">
                    <EyeFill /> D
                  </span>
                    <TextArea
                      id="olhoDireito"
                      rows="1"
                      :value="form.fields.olhoDireito"
                      :readonly="!canEdit"
                      @input="value => updateForm('olhoDireito', value)"
                      @blur="updateMedicalRecord(form)"
                      type="text"
                      class="form-control"
                      placeholder="Resultado"
                    />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <span for="simplified-right" class="tag-eye">
                    <EyeFill /> E
                  </span>
                    <TextArea
                      id="olhoEsquerdo"
                      rows="1"
                      :value="form.fields.olhoEsquerdo"
                      :readonly="!canEdit"
                      @input="value => updateForm('olhoEsquerdo', value)"
                      @blur="updateMedicalRecord(form)"
                      type="text"
                      class="form-control"
                      placeholder="Resultado"
                    />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          v-if="canEdit"
          class="btn blue-underline button-copy"
          @click="() =>copyEyeValues('olhoDireito','olhoEsquerdo')"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
          >
            <Copy/>
        </button>
      </b-row>
    </b-col>

    <b-col cols="12" v-if="!simplified">
      <b-row>
        <b-col>
          <div class="form-group">
            <label>Vítreo</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <TextArea
                    id="bio-vitreoDireito"
                    rows="1"
                    :value="form.fields.vitreoDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('vitreoDireito', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <TextArea
                    id="bio-vitreoEsquerdo"
                    rows="1"
                    :value="form.fields.vitreoEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('vitreoEsquerdo', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click=" () => copyEyeValues('vitreoDireito', 'vitreoEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <Copy/>
        </button>
      </b-row>

      <b-row>  
        <b-col>
          <div class="form-group">
            <label>Disco óptico</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <TextArea
                    id="bio-discoOpticoDireito"
                    rows="1"
                    :value="form.fields.discoOpticoDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('discoOpticoDireito', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <TextArea
                    id="bio-discoOpticoEsquerdo"
                    rows="1"
                    :value="form.fields.discoOpticoEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('discoOpticoEsquerdo', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click="
            () => copyEyeValues('discoOpticoDireito', 'discoOpticoEsquerdo')
          "
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <Copy/>
        </button>
      </b-row>

      <b-row>  
        <b-col>
          <div class="form-group">
            <label>Escavação</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <TextArea
                    id="bio-escavacaoDireito"
                    rows="1"
                    :value="form.fields.escavacaoDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('escavacaoDireito', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <TextArea
                    id="bio-escavacaoEsquerdo"
                    rows="1"
                    :value="form.fields.escavacaoEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('escavacaoEsquerdo', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click="
            () => copyEyeValues('escavacaoDireito', 'escavacaoEsquerdo')
          "
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <Copy/>
        </button>
      </b-row>

      <b-row>  
        <b-col>
          <div class="form-group">
            <label>Mácula</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <TextArea
                    id="bio-maculaDireito"
                    rows="1"
                    :value="form.fields.maculaDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('maculaDireito', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <TextArea
                    id="bio-maculaEsquerdo"
                    rows="1"
                    :value="form.fields.maculaEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('maculaEsquerdo', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click="() => copyEyeValues('maculaDireito', 'maculaEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <Copy/>
        </button>
      </b-row>

      <b-row>  
        <b-col>
          <div class="form-group">
            <label>Vasos</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <TextArea
                    id="bio-vasosDireito"
                    rows="1"
                    :value="form.fields.vasosDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('vasosDireito', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <TextArea
                    id="bio-vasosEsquerdo"
                    rows="1"
                    :value="form.fields.vasosEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('vasosEsquerdo', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click="() => copyEyeValues('vasosDireito', 'vasosEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <Copy/>
        </button>
      </b-row>

      <b-row>
        <b-col>
          <div class="form-group">
            <label>Retina</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <TextArea
                    id="bio-retinaDireito"
                    rows="1"
                    :value="form.fields.retinaDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('retinaDireito', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <TextArea
                    id="bio-retinaEsquerdo"
                    rows="1"
                    :value="form.fields.retinaEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('retinaEsquerdo', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click="() => copyEyeValues('retinaDireito', 'retinaEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <Copy/>
        </button>
      </b-row>

      <b-row>  
        <b-col>
          <div class="form-group">
            <label>Hipótese/Diagnóstico</label>
            <b-row>
              <b-col cols="12">
                <div class="input-eye mb-4">
                  <p class="tag-eye"><EyeFill /> D</p>
                  <TextArea
                    id="bio-hipoteseDiagnosticoDireito"
                    rows="1"
                    :value="form.fields.hipoteseDiagnosticoDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('hipoteseDiagnosticoDireito', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <div class="input-eye">
                  <p class="tag-eye"><EyeFill /> E</p>
                  <TextArea
                    id="bio-hipoteseDiagnosticoEsquerdo"
                    rows="1"
                    :value="form.fields.hipoteseDiagnosticoEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('hipoteseDiagnosticoEsquerdo', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                    placeholder="Resultado"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <button
          class="btn blue-underline button-copy"
          @click="() => copyEyeValues('hipoteseDiagnosticoDireito', 'hipoteseDiagnosticoEsquerdo')"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <Copy/>
        </button>
      </b-row>
    </b-col>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getCurrentUser } from '@/utils/localStorageManager'

export default {
  components: { 
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'), 
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    TextArea: () => import('@/components/General/TextArea'),
    Copy: () => import('@/assets/icons/copy.svg'),
   },
  async mounted() {
    await this.getStandards()
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.mapeamentoDeRetina,
      selectedForm: state => state.attendance.form.selected
    })
  },
  data: () => ({
    useStandard: false,
    autoCompleted: false,
    padrao: false,
    simplified: false,
    user: getCurrentUser(),
    standard: {},
  }),
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/mapeamentoDeRetina', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    async getStandards() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getStandards(this.user.id)
        this.standard = data.find(std => std.type === "mapeamento-de-retina")
        this.useStandard = this.standard?.active
        if (this.canEdit && this.useStandard) {
          this.simplified = !!this.standard.simplified
          this.updateForm('simplified', !!this.standard.simplified)
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    canFillData (currentValue) {
      return (
        currentValue === null 
        || currentValue === 'sem alterações' 
        || (typeof currentValue === 'string' && currentValue.trim() === '') 
      )
    },
    autoFill() {
      const newValue = this.autoCompleted ? 'sem alterações' : null;
      Object.keys(this.form.fields).filter(el => el !== 'simplified').map(key => {
        const currentValue = this.form.fields[key]
        if (this.canFillData(currentValue)) {
          this.updateForm(key, newValue)
        }
      })
      this.updateMedicalRecord(this.form)
    },
    copyEyeValues(rightEyeFieldName, leftEyeFieldName) {
      this.updateForm(leftEyeFieldName, this.form.fields[rightEyeFieldName])
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
    },
    handleSaveSimplified () {
      const keys = Object.keys(this.form.fields).filter(el => el !== 'simplified')
      const canSave = keys.some(key => {
        return ( this.form.fields[key] )
      })

      if (canSave) {
        this.updateMedicalRecord(this.form)
      }
    },
    handleUseSimplified () {
      this.simplified = !this.simplified
      this.updateForm('simplified', this.simplified)
      this.handleSaveSimplified()
    },
    canSetDefault(currentValue, standardKey) {
      return (
        currentValue === null 
        || ( this.standard && currentValue === this.standard?.value[standardKey] )
        || currentValue === 'sem alterações' 
        || (typeof currentValue === 'string' && currentValue.trim() === '')
      )
    },
    setPadrao(){
      for (const standardKey in this.standard.value){
        for (const key in this.form.fields) {
          if (key.includes(standardKey)) {
            const newValue = this.padrao ? this.standard.value[standardKey] : (this.autoCompleted ? 'sem alterações' : null);
            const currentValue = this.form.fields[key]
            if (!this.standard.value[standardKey]) continue
            if (this.canSetDefault(currentValue, standardKey)) {
              this.updateForm(key, newValue)
            }
          }
        }
      }
      this.updateMedicalRecord(this.form)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    }
  },
  watch: {
    form() {
      this.autoCompleted = Object.keys(this.form.fields).some(key => this.form.fields[key] === 'sem alterações')

      if (!this.standard?.value) return;
      
      this.padrao = Object.keys(this.form.fields).some(
        key => Object.keys(this.standard.value).some(
          stdKey => key.includes(stdKey) && this.standard.value[stdKey] && this.form.fields[key] === this.standard.value[stdKey]
        )
      )
    },
    'form.fields.olhoDireito'(value) {
      if (value && this.form?.fields?.simplified) {
        this.simplified = true
      }
    },
    'form.fields.olhoEsquerdo'(value) {
      if (value && this.form?.fields?.simplified) {
        this.simplified = true
      }
    },
    selectedForm: {
      immediate: true,
      deep: true,
      handler() {
        if(this.selectedForm?.label === 'Programa Glaucoma SUS'){
          this.simplified = true
          this.updateForm('simplified', !!this.simplified)
        }
      }
    },
    deep: true
  }
}
</script>
